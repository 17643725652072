import {
    SET_LOCATION_DATA,
    SET_VIEW_CART_FIELDS,
    UPDATE_PROJECT,
    RESET_LOCATION_DATA,
    RESET_GEOGRAPHY,
    RESET_PROJECT,
    SET_SHOW_UNAVAILABLE_ITEMS,
    SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS,
    SET_LAST_USED_JOBSITE,
    SET_LOCATION_LOADING,
    SET_SHOW_AVS_ERROR_IN_CAP
} from '../../aem-core-components/actions/constants';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import {
    SET_ACCOUNTS,
    SET_ADDRESS,
    SET_END_DATE,
    SET_FULFILLMENT_TYPE,
    SET_JOBSITES,
    SET_PICKUP_STORES,
    SET_SELECTED_STORE_DETAILS,
    SET_START_DATE,
    RESET_FILTER_STATE,
    UPDATE_FILTER_STATE,
    SET_SUGGESTED_JOBSITE_ADDRESS,
    SET_IS_SUGGESTIONS_LOADING,
    RESET_SUGGESTED_JOBSITE_ADDRESS,
    SET_RECOMMENDED_JOBSITES,
    SET_IS_JOBSITE_RECOMMENDATION_LOADING,
    EDIT_VIEW_CLOSE,
    EDIT_VIEW_OPEN,
    SET_BSR_LIST,
    EDIT_VIEW_DETAILS,
    SET_IS_CAP_DETAILS_UPDATING,
    RESET_SELECTED_STORE_DETAILS,
    SET_IS_FORM_STARTED_EVENT_TRIGGERED
} from './constants';
import initialFilterState from './initialFilterState';

const filterReducer = setSettingsCookieFunc => {
    return (state, action) => {
        switch (action.type) {
            case SET_ADDRESS:
                return {
                    ...state,
                    address: {
                        line1: action?.address?.line1 || state?.address?.line1,
                        line2: action?.address?.line2 || state?.address?.line2,
                        city: action?.address?.city || state?.address?.city,
                        state: action?.address?.state || state?.address?.state,
                        zip: action?.address?.zip || state?.address?.zip,
                        lat: action?.address?.lat || state?.address?.lat,
                        long: action?.address?.long || state?.address?.long
                    }
                };
            case SET_START_DATE:
                return {
                    ...state,
                    startDate: action?.startDate
                };
            case SET_END_DATE:
                return {
                    ...state,
                    endDate: action?.endDate
                };
            case SET_FULFILLMENT_TYPE:
                return {
                    ...state,
                    fulfillmentType: action?.fulfillmentType
                };
            case SET_SELECTED_STORE_DETAILS:
                localStorage.setItem(
                    STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS,
                    JSON.stringify(action?.selectedStoreDetails || {})
                );
                const storeDetails = action?.selectedStoreDetails?.pc ? action.selectedStoreDetails : action?.isNoStore;
                const locationPCObj = {
                    distance: action?.distance || storeDetails?.distance,
                    lat: action?.lat || storeDetails?.latitude,
                    long: action?.long || storeDetails?.longitude,
                    localLat: action?.localLat || '',
                    localLong: action?.localLong || '',
                    pc: action?.pc || storeDetails?.pc,
                    specialtyTypes: action?.specialtyTypes || storeDetails?.specialtyTypes
                };
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ, JSON.stringify(locationPCObj));

                return {
                    ...state,
                    selectedStoreDetails: action?.selectedStoreDetails || {}
                };
            case RESET_SELECTED_STORE_DETAILS:
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS, JSON.stringify({}));
                return {
                    ...state,
                    selectedStoreDetails: {}
                };
            case SET_PICKUP_STORES:
                return {
                    ...state,
                    pickupStores: action?.pickupStores
                };
            case SET_JOBSITES:
                return {
                    ...state,
                    jobsites: action?.jobsites
                };
            case SET_ACCOUNTS:
                return {
                    ...state,
                    accounts: action?.accounts
                };
            case UPDATE_FILTER_STATE:
                return {
                    ...state,
                    ...action?.filter
                };
            case RESET_FILTER_STATE:
                return {
                    ...initialFilterState
                };
            case SET_VIEW_CART_FIELDS:
                return {
                    ...state,
                    viewCart: {
                        ...state.viewCart,
                        [action.key]: action.value == String(null) ? '' : action.value //TODO handle invalid date issue
                    }
                };
            case UPDATE_PROJECT:
                setSettingsCookieFunc(null, action);
                return {
                    ...state,
                    projectDetails: {
                        locationPC: action.locationPC,
                        projectName: action.projectName,
                        projectAddress1: action.projectAddress1,
                        projectAddress2: action.projectAddress2,
                        selectedProjectJobId: action.selectedProjectJobId,
                        selectedRMJobId: action.selectedRMJobId,
                        selectedProjectLatititude: action.selectedProjectLatititude,
                        selectedProjectLongitude: action.selectedProjectLongitude,
                        selectedProjectState: action.selectedProjectState,
                        selectedProjectCity: action.selectedProjectCity,
                        selectedProjectZip: action.selectedProjectZip,
                        primaryContactName: action.primaryContactName,
                        phoneNumber: action.phoneNumber,
                        accessNotes: action.accessNotes,
                        poNumber: action.poNumber,
                        isValueUpdated: action.isValueUpdated || false,
                        CJDLVY: action.CJDLVY
                    }
                };
            case SET_LOCATION_DATA:
                setSettingsCookieFunc(null, action);
                localStorage.setItem(
                    STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART,
                    JSON.stringify({
                        ...state.viewCart,
                        pc: action?.pc || '',
                        location: action?.location || '',
                        jobSiteCity: action?.jobSiteCity || '',
                        jobSiteState: action?.jobSiteState || '',
                        jobSiteZip: action?.jobSiteZip || '',
                        lat: action?.lat || '',
                        long: action?.long || '',
                        jobSiteAddr2: action?.jobSiteAddr2 || '',
                        showCreateProject: action?.showCreateProject || state?.viewCart?.showCreateProject,
                        pcLat: action?.pcLat || state.viewCart.pcLat || '',
                        pcLong: action?.pcLong || state.viewCart.pcLong || ''
                    })
                );
                return {
                    ...state,
                    viewCart: {
                        ...state.viewCart,
                        pc: action?.pc || '',
                        location: action?.location || '',
                        jobSiteCity: action?.jobSiteCity || '',
                        jobSiteState: action?.jobSiteState || '',
                        jobSiteZip: action?.jobSiteZip || '',
                        lat: action?.lat || '',
                        long: action?.long || '',
                        jobSiteAddr2: action?.jobSiteAddr2 || '',
                        pcLat: action?.pcLat || state.viewCart.pcLat || '',
                        pcLong: action?.pcLong || state.viewCart.pcLong || ''
                    }
                };
            case RESET_LOCATION_DATA:
                localStorage.setItem(
                    STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART,
                    JSON.stringify({
                        ...state.viewCart,
                        showCreateProject: false,
                        location: '',
                        jobSiteCity: '',
                        jobSiteState: '',
                        jobSiteZip: '',
                        lat: '',
                        long: '',
                        jobSiteAddr2: ''
                    })
                );
                localStorage.setItem('isCreditNewAddress', false);
                return {
                    ...state,
                    viewCart: {
                        ...state.viewCart,
                        showCreateProject: false,
                        location: '',
                        jobSiteCity: '',
                        jobSiteState: '',
                        jobSiteZip: '',
                        lat: '',
                        long: '',
                        jobSiteAddr2: ''
                    }
                };
            case RESET_GEOGRAPHY:
                localStorage.setItem(
                    STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART,
                    JSON.stringify({
                        ...state.viewCart,
                        lat: '',
                        long: '',
                        pc: 0,
                        pcLat: '',
                        pcLong: ''
                    })
                );
                return {
                    ...state,
                    viewCart: {
                        ...state.viewCart,
                        lat: '',
                        long: '',
                        pc: 0,
                        pcLat: '',
                        pcLong: ''
                    }
                };
            case RESET_PROJECT:
                return {
                    ...state,
                    projectDetails: {
                        locationPC: null,
                        projectName: '',
                        projectAddress1: '',
                        projectAddress2: '',
                        selectedProjectJobId: '',
                        selectedProjectLatititude: '',
                        selectedProjectLongitude: '',
                        selectedProjectState: '',
                        selectedProjectCity: '',
                        selectedProjectZip: '',
                        primaryContactName: '',
                        phoneNumber: '',
                        accessNotes: '',
                        poNumber: '',
                        isValueUpdated: false,
                        CJDLVY: ''
                    }
                };
            case SET_IS_SUGGESTIONS_LOADING:
                return {
                    ...state,
                    isJobsiteAddressSuggestionLoading: action?.isLoading
                };
            case SET_SUGGESTED_JOBSITE_ADDRESS: {
                return {
                    ...state,
                    jobsiteSuggestions: action?.jobsitesAndAddresses?.jobsiteSuggestions || [],
                    addressSuggestions: action?.jobsitesAndAddresses?.addressSuggestions || []
                };
            }
            case RESET_SUGGESTED_JOBSITE_ADDRESS: {
                return {
                    ...state,
                    jobsiteSuggestions: [],
                    addressSuggestions: []
                };
            }
            case SET_RECOMMENDED_JOBSITES: {
                return {
                    ...state,
                    recommendedJobsites: action?.recommendedJobsites || []
                };
            }
            case SET_IS_JOBSITE_RECOMMENDATION_LOADING: {
                return {
                    ...state,
                    isJobsiteRecommendationLoading: action?.isLoading
                };
            }
            case SET_LAST_USED_JOBSITE: {
                return {
                    ...state,
                    lastUsedJobsite: action?.lastUsedJobsite
                };
            }
            case EDIT_VIEW_CLOSE: {
                return {
                    ...state,
                    isEditView: false
                };
            }
            case EDIT_VIEW_OPEN: {
                return {
                    ...state,
                    isEditView: true
                };
            }
            case EDIT_VIEW_DETAILS: {
                return {
                    ...state,
                    editViewSource: action?.editViewSource
                };
            }
            case SET_BSR_LIST: {
                return {
                    ...state,
                    bsrList: action.bsrList
                };
            }
            case SET_IS_CAP_DETAILS_UPDATING:
                return {
                    ...state,
                    isCapDetailsUpdating: action?.isCapDetailsUpdating
                };
            case SET_SHOW_UNAVAILABLE_ITEMS:
                return {
                    ...state,
                    showUnavailableItems: action?.showUnavailableItems
                };
            case SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS:
                return {
                    ...state,
                    showNoResultsForUnavailableItems: action?.showNoResultsForUnavailableItems
                };
            case SET_LOCATION_LOADING:
                return {
                    ...state,
                    isLocationsLoading: action?.isLocationsLoading
                };
            case SET_IS_FORM_STARTED_EVENT_TRIGGERED:
                return {
                    ...state,
                    isFormStartedEventTriggered: action?.isFormStartedEventTriggered
                };
            case SET_SHOW_AVS_ERROR_IN_CAP: {
                return {
                    ...state,
                    showAvsErrorInCap: action?.showAvsErrorInCap || false
                };
            }
            default:
                return state;
        }
    };
};

export default filterReducer;
