import { checkIsInstorePickup, getInitialProjectStateFromCookie, storeCheckoutData } from '../global/utils/commonUtils';
import { STORAGE_CONFIG } from '../../constants/storageConfig';

const initialFilterState = {
    viewCart: {
        location: '',
        locationByPlaces: '',
        pc: 0,
        customerNumber: '0',
        isInStorePickup: checkIsInstorePickup(),
        //roundtrip address
        lat: '',
        long: '',
        jobSiteAddr2: '',
        jobSiteCity: '',
        jobSiteState: '',
        jobSiteZip: '',
        showDeliveryEstimate: false,
        showCreateProject: false,
        showAddOnsOverlay: false,
        createProjectSource: '',
        checkPCInterval: 0,
        inactivePCAlert: false,
        pcLat: '',
        pcLong: '',
        distanceFromPc: 0
    },
    projectDetails: getInitialProjectStateFromCookie(),
    address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        lat: '',
        long: ''
    },
    startDate: '',
    endDate: '',
    fulfillmentType: '',
    selectedStoreDetails: storeCheckoutData(STORAGE_CONFIG.SESSION_STORAGE.SELECTEDSTOREDATA) || {},
    pickupStores: null, // null to identify the locations api is not called yet, will save {} as value when the locations api return no stores
    accounts: [],
    jobsites: [],
    jobsiteSuggestions: [], // suggestions based on search query
    addressSuggestions: [],
    isJobsiteAddressSuggestionLoading: false,
    recommendedJobsites: [], // recommendations based on account
    isJobsiteRecommendationLoading: true,
    isEditView: false,
    editViewSource: '',
    bsrList: [],
    isCapDetailsUpdating: false,
    showUnavailableItems: false,
    showNoResultsForUnavailableItems: false,
    lastUsedJobsite: {},
    isLocationsLoading: false,
    isFormStartedEventTriggered: false,
    showAvsErrorInCap: false
};
export default initialFilterState;
