export const STORAGE_CONFIG = {
    LOCAL_STORAGE: {
        ISCREDITNEWADDRESS: 'isCreditNewAddress',
        VIEWCART: 'viewCart',
        COMPANYID: 'companyID',
        GUID: 'guid',
        ISCHECKOUTLOADING: 'isCheckoutLoading',
        PROJECTDETAILS: 'projectDetails',
        ISINSTOREPICKUP: 'isInStorePickup',
        SHOWGUESTCHECKOUTLOGIN: 'showGuestCheckoutLogin',
        PUNCHOUTUSERDATA: 'punchoutUserData',
        CHECKOUTREFERRER: 'checkoutReferrer',
        USER_LOGIN: 'user-login',
        FIRSTNAME: 'firstName',
        YOURDETAILS: 'yourDetails',
        RECENTLYVIEWED: 'recentlyViewed',
        SELECTEDSTOREDETAILS: 'selectedStoreDetails',
        NEARBYSTORES: 'nearbyStores',
        STARTINGLOCATIONFORGETDIRECTIONS: 'startingLocationForGetDirections',
        JOBSITE_SEARCH_KEYWORD: 'jobsiteSearchKeyword',
        SELECTED_ACCOUNT_ID: 'selectedAccountId',
        IP_ADDRESS: 'ipAddress',
        CARTTOTALQUANTITY: 'cartTotalQuantity',
        ISNEWADDRESSONOVERLAY: 'isNewAddressOnOverlay',
        CJDLVY: 'CJDLVY',
        FOURHOURRENTALS: 'fourHourRentals',
        SHOWEDITQUOTEBANNER: 'showEditQuoteBanner',
        TRANSMITTED_QUOTE_ID: 'transmittedQuoteId',
        DISPLAY_QUOTE_ID: 'displayQuoteId',
        ISCAPDETAILSSAVED: 'isCapDetailsSaved',
        PLACEID: 'placeId',
        STARTDATE: 'startDate',
        ENDDATE: 'endDate',
        LOCALSELECTEDSTOREDETAILS: 'localSelectedStoreDetails',
        LOCATIONSEARCHFIELDS: 'locationSearchFields',
        CORP_ACCOUNT_DETAILS: 'corpAccountDetails',
        ISCREATEJOBSITE: 'isCreateJobsite'
    },
    SESSION_STORAGE: {
        USERPROFILE: 'userProfile',
        MKTOTOKEN: 'mktoToken',
        MKTOLOGGEDIN: 'mktoLoggedIn',
        OVERRIDEPC: 'overridePc',
        OVERRIDEPCLATITUDE: 'overridePcLatitude',
        OVERRIDEPCLONGITUDE: 'overridePcLongitude',
        ISOPEN: 'isOpen',
        PUNCHOUTUSERDATA: 'punchoutUserData',
        SBMID: 'sbmid',
        SBSID: 'sbsid',
        CIF_MAGENTOTOKEN: 'cif.magentoToken',
        CIF_USERTOKEN: 'cif.userToken',
        ISADDONVISIBLE: 'isAddOnVisible',
        CARTCONTEXT: 'cartContext',
        PAYMENTSTEP: 'paymentStep',
        FORMSTEP: 'formStep',
        GUESTUSEREMAIL: 'guestUserEmail',
        DLNUMBERGUESTUSER: 'dlNumberGuestUser',
        DLSTATEGUESTUSER: 'dlStateGuestUser',
        TIMEOUTMESSAGETIMERID: 'timeoutMessageTimerId',
        LOCATIONPCOBJ: 'locationPCObj',
        ANALYSISREGION: 'analysisRegion',
        SOURCEPCOBJ: 'sourcePCObj',
        ARROWCLICK: 'arrowClick',
        HOMEASSETSRATES: 'homeAssetsRates',
        PROJECTLOADINGTIMERID: 'projectLoadingTimerId',
        MARKETOFEED: 'marketoFeed',
        ISUSERPRESUBSCRIBED: 'isUserPreSubscribed',
        ISSOURCESLOADING: 'isSourcesLoading',
        ISLOCATIONFORMNOTDIRTY: 'ISLOCATIONFORMNOTDIRTY',
        ISJOBSITESFETCHED: 'isJobsitesFetched',
        RECOMMENDED_JOBSITE: 'recommendedJobsite',
        ORDER_ESTIMATES_FINAL_TOTAL: 'orderEstimatesFinalTotal',
        IS_SOURCES_TIER_2_RADIUS: 'isSourcesTier2Radius',
        IS_LOCATIONS_TIER_2_RADIUS: 'isLocationsTier2Radius',
        IS_CART_UPDATED_WITH_PRICE: 'isCartUpdatedWithPrice',
        USERTYPE: 'userType',
        ESTIMATES_PICKUP: 'estimatePickup',
        ESTIMATES_DELIVERY: 'estimateDelivery',
        CHECKOUT_CURRENT_STEP: 'currentStep',
        USER_DATA_OBJ: 'userDataObj',
        CID_PC_LIST: 'cidPcList',
        ATP_PC_LIST: 'atpPcList',
        FILTER_CONTEXT: 'filterContext',
        SELECTEDSTOREDETAILS_FOR_ROUND_TRIP: 'selectedStoreDetailsForRoundTrip',
        SUCCESSFULLY_TRANSMITTED_QUOTE_ID: 'sucessfullyTransmittedQuoteId',
        BSR_PC_LIST: 'bsrPcList',
        ATP_STORES_CATSKU: 'atp_stores_catsku',
        IS_CREDIT_FULFILLMENT_SET: 'isCreditFulfillmentSet',
        CCA_SUBMISSION_ID: 'ccasubmissionid',
        DATA_TO_FINALIZE_CALL: 'dataToFinalizeCall',
        REDIRECTED_TO_EDIT_FLOW: 'redirectedToEditFlow',
        MY_FAVORITES_DROPDOWN_DATA: 'myFavouritesDropdownData',
        P2P_LEFT_SIDE_NAV: 'leftSideNavigation',
        DISABLE_CURRENT_LOCATION: 'disableCurrentLocation',
        CUSTIDENT: 'custIdent',
        CCA_GLOBAL_ERROR: `cca_error`,
        IS_FIRST_CART_LOAD: 'isFirstCartLoad',
        QUOTE_DEFAULT_SELECTED_FIELDS: 'QuoteDefaultSelectedFields',
        COACHMARK_OBJ: 'coachmarkObj',
        IS_PRODUCT_FOR_COACHMARK_SELECTED: 'isProductForCoachmarkSelected',
        IS_ESTIMATE_ON_CART_OPEN_REQUIRED: 'isEstimateOnCartOpenRequired',
        LOCALWISHLISTDATA: 'localWishListData',
        LOCAL_ASSETS_DATA: 'localAssetsData',
        IS_ALL_JOBSITE_FETCHED: 'isAllJobsitesFetched',
        GR_TOKEN: 'grToken',
        USERPROFILE: 'userProfile',
        CHECKOUTUSERDETAILS: 'checkoutUserDetails',
        TIMEZONERESPONSE: 'timeZoneResponse',
        SELECTEDSTOREDATA: 'selectedStoreData',
        STARTDATESLOTS: 'startDateSlots',
        ENDDATESLOTS: 'endDateSlots',
        ISUSERDETAILSSET: 'isUserDetailsSet',
        GR_EMAIL: 'grEmail',
        CART_ITEMS: 'cartItems',
        CLIENT_IP_ADDRESS: 'clientIpAddress',
        SALESREPID: 'salesRepId',
        SFID: 'sfId',
        SESSIONID: 'sessionId',
        USER_EXISTS: 'userExists',
        DISCARD_OLD_CART: 'discardOldCart',
        IS_CAP_IN_EDIT_MODE: 'isCapInEditMode',
        PICKUPTIMESLOTS: 'pickupTimeSlots',
        RETURNTIMESLOTS: 'returnTimeSlots',
        AUTHACCESSTOKEN: 'auth_access_token',
        SUBMISSION_ID: 'submissionid',
        REFERER: 'referer',
        AVS_MISMATCH_RESPONSE: 'avsMismatchResponse'
    },
    COOKIES: {
        CIF_MAGENTOTOKEN: 'cif.magentoToken',
        SBUID: 'sbuid',
        SBAID: 'sbaid',
        CIF_CART: 'cif.cart',
        CURRENTWYNNEACCOUNTKEY: 'CurrentWynneAccountKey',
        CURRENTWYNNEACCOUNTNAMEKEY: 'CurrentWynneAccountNameKey',
        CURRENTWYNNEACCOUNTCIDKEY: 'CurrentWynneAccountCIDKey',
        ACCESS_TOKEN: 'access_token',
        REFRESH_TOKEN: 'refresh_token',
        SETTINGS: 'Settings',
        USERTYPE: 'userType',
        ADDPRODUCT: 'addProduct',
        CIF_USERTOKEN: 'cif.userToken',
        ACCESSTOKEN: 'accesstoken',
        COMPANYID: 'companyId',
        FIRSTNAME: 'firstName',
        REFRESHTOKEN: 'refreshtoken',
        SESSIONID: 'sessionid',
        GUEST_USER_ID: 'guest_user_id',
        GUESTSESSIONID: 'guestsessionid',
        LOGINTOKEN: 'loginToken',
        PUNCHOUT_RETURN_URL: 'punchout_return_url',
        PUNCHOUT_REDIRECT_URL: 'punchout_redirect_url',
        TOKENEXPTIME: 'tokenExpTime',
        LOCATIONPROMPT: 'locationPrompt',
        POS: 'pos',
        ID_TOKEN: 'id_token',
        TOKEN_TYPE: 'token_type',
        EXPIRES_IN: 'expires_in',
        AUTHORITY_TYPE: 'authority_type'
    }
};
