import React, { memo, useEffect, useRef } from 'react';
import { bool, func } from 'prop-types';
import { useIntl } from 'react-intl';
import { useFilterState } from '../../filterContext';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import useMedia from '../../../../hooks/useMedia';
import ErrorInput from '../../../global/atoms/errorInput/ErrorInput';
import Button from '../../../global/atoms/button';
import GlobalModal from '../../../global/atoms/globalModal/GlobalModal';
import SideDrawerModal from '../../../global/modules/modals/SideDrawerModal';
import AlertCircle from '../../../../resources/images/alert-circle-black.svg';
import './EditOnlyPersistantCapHeader.scss';
import { MEDIA_TYPE } from '../../../../constants/screenConstants';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';

const EditOnlyPersistantCapHeader = ({
    renderRentalDetailsView,
    renderRentalDetailsDrawerContent,
    renderUpdateBtn,
    toggleEditMode,
    isEditView,
    setShowPickupStoreAlert,
    showPickupStoreAlert,
    setNoLocationPC,
    setShowAlertBanner,
    showAlertBanner,
    updateRentalViewDetails,
    isCapInteracted,
    sendAnalyticsForNoStoreNearBy,
    capHeaderOverlayHandler
}) => {
    const capIntl = useIntl();
    const [{ showAvsErrorInCap }] = useFilterState();
    const avsMismatchResponse = JSON.parse(
        sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.AVS_MISMATCH_RESPONSE) || '{}'
    );
    const { avsError } = avsMismatchResponse || {};
    const mediaType = useMedia();
    const { sendEventsForUnavailableItem } = useAnalyticsContext();
    const isEditViewOpen = isEditView || isCapInteracted;
    const editCapRef = useRef(null);

    useEffect(() => {
        // on opening cap from other actions, only cap section comes into view
        // if the error strip is present, it is outside the view,
        // hence manually scrolling error strip into the view
        if (showAvsErrorInCap && isEditViewOpen && mediaType === MEDIA_TYPE.DESKTOP) {
            window.scrollTo(0, 0);
        }
    }, [showAvsErrorInCap, isEditViewOpen]);

    useEffect(() => {
        if (showPickupStoreAlert) {
            sendEventsForUnavailableItem(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_INVENTORY_ALERT_VIEWED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_UNAVAILABLE_FROM_PC
            );
            sendAnalyticsForNoStoreNearBy();
        }
    }, [showPickupStoreAlert]);

    useEffect(() => {
        const getMainTag = ENV_CONFIG.MAIN_SECTION;

        if (isEditViewOpen) {
            if (mediaType === MEDIA_TYPE.DESKTOP) {
                editCapRef.current.focus();
            }
            getMainTag?.classList?.add('main-wrapper--cap-editmode');
        } else {
            getMainTag?.classList?.remove('main-wrapper--cap-editmode');
        }
    }, [isEditViewOpen]);

    const onChangeWarningHandler = async () => {
        setShowAlertBanner(true);
        setNoLocationPC(false);
        setShowPickupStoreAlert(false);
        await updateRentalViewDetails(false);
    };

    const onCloseNoStoresModal = () => {
        setShowPickupStoreAlert(false);
    };

    const closeModalWhenFocusIsOutOfButton = () => {
        if (isEditView) {
            capHeaderOverlayHandler();
        }
    };

    const title = () => {
        return (
            <div className="alert-title">
                <AlertCircle className="alertCircle__icon" />
                {capIntl.formatMessage({ id: 'cap:pickup-store-warning' })}
            </div>
        );
    };
    const content = () => {
        return (
            <>
                {capIntl.formatMessage({ id: 'cap:location-info-message-desc-prefix' })}{' '}
                <a
                    href={capIntl.formatMessage({ id: 'cap:no-address-helpline-ref' })}
                    className="searchresult__notfound-link"
                    title={capIntl.formatMessage({ id: 'cap:no-store-help-text' })}>
                    {capIntl.formatMessage({ id: 'cap:no-address-helpline' })}
                </a>{' '}
                {capIntl.formatMessage({ id: 'cap:pickup-no-store-warning-text-sufix' })}
            </>
        );
    };

    const footer = () => {
        return (
            <Button
                type="button"
                className="button button-primary button-block"
                onClick={onChangeWarningHandler}
                buttonAriaLabel={capIntl.formatMessage({ id: 'timeout-continue-text' })}>
                {capIntl.formatMessage({ id: 'cap:timeout-continue-text' })}
            </Button>
        );
    };

    const renderErrorStrip = () => {
        if (isEditViewOpen) {
            return <ErrorInput customClassName="cap-location-error__strip" errorMsg={avsError} />;
        }
    };

    const renderDesktopView = () => (
        <div className={`top-cap ${isEditViewOpen ? 'top-cap-active' : 'top-cap-inactive'}`}>
            {isEditViewOpen && (
                <div
                    ref={editCapRef}
                    tabIndex="0"
                    className={`top-cap__container ${isEditView ? 'top-cap__container--edit' : ''} ${
                        showAlertBanner ? 'pickupstore__alert__banner' : ''
                    } ${showAvsErrorInCap ? 'cap-location-error' : ''}`}>
                    {showAvsErrorInCap && renderErrorStrip()}
                    {renderRentalDetailsView()}
                    <div className="start-renting-div">{renderUpdateBtn()}</div>
                </div>
            )}
        </div>
    );

    const editDrawerHeader = <h5>{capIntl.formatMessage({ id: 'cap:rental-details' })}</h5>;

    const renderSideDrawer = () => (
        <SideDrawerModal
            isModalOpen={isEditViewOpen}
            header={editDrawerHeader}
            content={renderRentalDetailsDrawerContent()}
            footer={renderUpdateBtn()}
            showModalHeader
            modalHeaderClass="edit-drawer__header"
            modalContentClass="edit-drawer__content"
            modalFooterClass="edit-drawer__footer"
            handleModalToggle={toggleEditMode}
        />
    );

    return (
        <>
            {showPickupStoreAlert && (
                <GlobalModal
                    isOpen
                    title={title()}
                    handleClose={onCloseNoStoresModal}
                    footer={footer()}
                    content={content()}
                    className="pickupstore__alert"
                    footerClasses="pickupstore__alert__footer"
                />
            )}
            {mediaType === MEDIA_TYPE.DESKTOP ? renderDesktopView() : renderSideDrawer()}
        </>
    );
};

EditOnlyPersistantCapHeader.defaultProps = {
    renderRentalDetailsView: () => {},
    renderRentalDetailsDrawerContent: () => {},
    renderUpdateBtn: () => {},
    toggleEditMode: () => {},
    isEditView: false,
    setNoLocationPC: () => {},
    setShowAlertBanner: () => {},
    showAlertBanner: false,
    updateRentalViewDetails: () => {},
    setShowPickupStoreAlert: () => {},
    showPickupStoreAlert: false,
    isCapInteracted: false,
    sendAnalyticsForNoStoreNearBy: () => {},
    capHeaderOverlayHandler: () => {}
};

EditOnlyPersistantCapHeader.propTypes = {
    renderRentalDetailsView: func,
    renderRentalDetailsDrawerContent: func,
    renderUpdateBtn: func,
    toggleEditMode: func,
    isEditView: bool,
    setNoLocationPC: func,
    setShowAlertBanner: func,
    showAlertBanner: bool,
    updateRentalViewDetails: func,
    setShowPickupStoreAlert: func,
    showPickupStoreAlert: bool,
    isCapInteracted: bool,
    sendAnalyticsForNoStoreNearBy: func,
    capHeaderOverlayHandler: func
};

export default memo(EditOnlyPersistantCapHeader);
